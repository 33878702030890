<template>


    <div class="mt-4 onboarding-box" style="display: flex; justify-content: center;">
        <h1 style="font-weight: 800 !important;">Start effortlessly generating documentation</h1>
        <div class="gradient-heading">
            <span class="filled-heading">Your onboarding journey</span>
        </div>
    </div>



    <div ref="sectionholder" class="sections-holder">
        <Button style="color: white !important" label="Help me find what extension I need" @click="toggleFilter" text
            rounded aria-label="Filter">
            <img :src="filterIcon" />
            Help me find what extension I need
        </Button>
        <Popover ref="filter">
            <div class="flex flex-col gap-4">
                <div v-for="category of documentationCategories" :key="category.key" class="flex items-center">
                    <Checkbox v-model="selectedCategories" :inputId="category.key" name="category"
                        :value="category.name" />
                    <label class="ml-2" :for="category.name">{{ category.key }}</label>
                </div>
            </div>

        </Popover>






        <div
            style="display: flex !important; gap: 2em; justify-content: center; height: 100%; align-items: flex-start !important;  overflow: scroll;">
            <div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 2em; overflow-y: scroll; margin-bottom: 10em;"
                class="mt-1">
                <BlockUI :blocked="vsCodeblocked">
                    <Card class="p-4" style="overflow: hidden">
                        <template #header>
                            <div style="display: flex; align-items: center;">
                                <img alt="user header" style="width: 40px; height: 40px;"
                                    src="https://cdn.glitch.global/40d5f2e1-d6f1-4b4e-b603-44a0049b2e40/vscode.png?v=1659102647077" />
                                <h3 class="ml-4">VS Code extension</h3>
                            </div>
                        </template>
                        <template #subtitle>Generate and maintain docs directly from VS Code</template>
                        <template #content>
                            <p class="m-0">

                            </p>
                        </template>
                        <template #footer>
                            <div style="justify-content: flex-end" class="flex gap-4 mt-1 footer-actions">
                                <a href="https://www.docs.dev/docs/Initial-Setup/Install-VS-Code-Extension-and-Get-Started" target="_blank"
                                    class="onboaring-secondary-button">
                                    Read the docs
                                </a>
                                <button class="onboarding-button" label="Save" @click="toggle">Get started</button>
                                <Popover class="popover-content" ref="vscode-op" unstyled>
                                    <div style="justify-content: flex-end; display: flex; flex-direction: column;">
                                        <a href="https://www.docs.dev/docs/Dev-Docs-Quickstart"
                                            target="_blank" style="color: #A2A1A5" class="p-button p-button-text">2
                                            minute
                                            quickstart</a>
                                        <a href="https://marketplace.visualstudio.com/items?itemName=dev-docs.dev-docs"
                                            target="_blank" style="color: #A2A1A5" class="p-button p-button-text">VS Code
                                            extension</a>
                                    </div>
                                </Popover>
                            </div>
                        </template>
                    </Card>

                </BlockUI>

                <Card v-tooltip.top="'Use Dev-Docs in 2 Minutes'" class="onboarding-loom-card"
                    style="overflow: hidden;">
                    <template #content>
                        <div ref="loomIframe" style="position: relative; width: 100%; height: 100%;">
                            <iframe @load="setupFullscreenHandler"
                                src="https://www.loom.com/embed/243da2f19de445e3a8d66ca8c5dc61cc?hideEmbedTopBar=true"
                                frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
                        </div>
                    </template>
                </Card>
                <BlockUI :blocked="githubActionBlocked">
                    <Card class="p-4">
                        <template #header>
                            <div style="display: flex; align-items: center;">
                                <img alt="user header" style="width: 40px; height: 40px;" :src="githubIcon" />
                                <h3 class="ml-4">GitHub App and Action</h3>
                            </div>
                        </template>
                        <template #subtitle>GitHub App/Action allows you to generate docs from your CI/CD</template>
                        <template #content>
                            <p class="m-0">

                            </p>
                        </template>
                        <template #footer>
                            <div style="justify-content: flex-end" class="flex gap-4 mt-1 footer-actions">
                                <a href="https://www.dev-docs.dev/docs/Generate-Internal-Docs-at-Github-Commit"
                                    target="_blank" class="onboaring-secondary-button">Read the
                                    Docs</a>
                                <button @click="githubActionsDialog = true" class="onboarding-button" label="Save">Get started</button>
                            </div>
                        </template>
                    </Card>
                </BlockUI>
                <BlockUI>
                    <Card class="p-4">
                        <template #header>
                            <div style="display: flex; align-items: center;">
                                <img alt="user header" style="width: 40px; height: 40px;" :src="logo" />
                                <h3 class="ml-4">Read the Dev-Docs quickstart documentation</h3>
                            </div>
                        </template>
                        <template #subtitle>Get a quick taste of how Dev-Docs works and what you can do</template>
                        <template #content>
                            <p class="m-0">

                            </p>
                        </template>
                        <template #footer>
                            <div style="justify-content: flex-end" class="flex gap-4 mt-1 footer-actions">
                                <a href="https://www.dev-docs.dev/docs" target="_blank"
                                    class="onboaring-secondary-button">Read the docs</a>
                                <a href="https://www.docs.dev/docs/Dev-Docs-Quickstart"
                                    target="_blank" class="onboarding-button">Quickstart</a>
                            </div>
                        </template>
                    </Card>
                </BlockUI>
                <BlockUI :blocked="chromeBlocked">
                    <Card class="p-4">
                        <template #header>
                            <div style="display: flex; align-items: center;">
                                <img alt="user header" style="width: 40px; height: 40px;" :src="chromeIcon" />
                                <h3 class="ml-4">Use the Chrome extension to generate docs for your UI</h3>
                            </div>
                        </template>
                        <template #subtitle>Click through your UI and generate a markdown step by step guide</template>
                        <template #content>
                            <p class="m-0">

                            </p>
                        </template>
                        <template #footer>
                            <div style="justify-content: flex-end" class="flex gap-4 mt-1 footer-actions">
                                <a href="https://www.dev-docs.dev/docs/Using-the-Chrome-Extension" target="_blank"
                                    class="onboaring-secondary-button">Read the docs</a>
                                <a href="https://chromewebstore.google.com/detail/dev-docs/jdfpljfedojaigjibfnnobbmgmlfkibb"
                                    target="_blank" class="onboarding-button">Install</a>
                            </div>
                        </template>
                    </Card>
                </BlockUI>
                <BlockUI :blocked="deployBlocked">
                    <Card class="p-4">
                        <template #header>
                            <div style="display: flex; align-items: center;">
                                <img alt="user header" style="width: 40px; height: 40px;" :src="deployIcon" />
                                <h3 class="ml-4">Manage your user-facing documentation</h3>
                            </div>
                        </template>
                        <template #subtitle>Mantain and deploy your user facing markdown docs from Dev-Docs</template>
                        <template #content>
                            <p class="m-0">

                            </p>
                        </template>
                        <template #footer>
                            <div style="justify-content: flex-end" class="flex gap-4 mt-1 footer-actions">
                                <a href="https://www.dev-docs.dev/docs/external-docs/How-to-Get-Started-With-External-Docs"
                                    target="_blank" class="onboaring-secondary-button">Read the
                                    Docs</a>
                                <RouterLink class="onboarding-button" to="/users_docs">
                                    Deploy
                                </RouterLink>
                            </div>
                        </template>
                    </Card>
                </BlockUI>
            </div>
        </div>
    </div>




    <github-drawer v-if="integrationDrawer" :open="integrationDrawer" title="github"
        @reset="integrationDrawer = false" />




    <!-- Existing code -->
    <Dialog v-model:visible="githubActionsDialog" :modal="true" :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" maximizable>
        <div class="mt-8 mb-8">
        <h3>Setup the Dev-Docs GitHub App</h3>
        <span class="p-card-subtitle">One minute, one click set up to make using Dev-Docs with your GitHub a breeze</span>
        <div style="display: flex;  flex-direction: row; justify-content: flex-end; align-items: flex-end;">
            <a class="p-button" href="https://github.com/apps/dev-docs-github-app" target="_blank">Install Github App</a>
        </div>
    </div>
        <Divider layout="horizontal" class="flex md:hidden" align="center"><b>OR</b></Divider>
        <h3>Setup a GitHub Action</h3>
        <span class="mb-10 p-card-subtitle">Use the Github Action to generate documentation from your CI/CD</span>
        <Accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
            <AccordionPanel value="0">
                <AccordionHeader>Generate a Dev-Docs API Key</AccordionHeader>
                <AccordionContent>
                    <APIKeySettings />
                </AccordionContent>
            </AccordionPanel>
        </Accordion>
        <span class="mt-8 mb-4 p-card-subtitle">Copy The Github Actions Code to add To Your Repo</span>
        <TabView>
            <TabPanel header="Generate Internal Docs">
                <h3>Generate Internal Docs</h3>
                <div style="display: flex; align-items: flex-end; justify-content: flex-end;">
                    <Button
                        @click="copySnippet(generateSnippet(org, 'generate_internal_docs', 'Generate Internal Docs'))">Copy</Button>
                </div>

                <pre style="max-width: 100%; max-height: 100%; overflow: auto;">
                <code>
                    {{ generateSnippet(org, "generate_internal_docs", "Generate Internal Docs") }}
                </code>
            </pre>
            </TabPanel>
            <TabPanel header="Audit Content">
                <h3>Audit Content</h3>
                <div style="display: flex; align-items: flex-end; justify-content: flex-end;">
                    <Button
                        @click="copySnippet(generateSnippet(org, 'generate_internal_docs', 'Generate Internal Docs'))">Copy</Button>
                </div>
                <pre style="max-width: 100%; max-height: 100%; overflow: auto;">
                <code>
                    {{ generateSnippet(org, "generate_internal_docs", "Generate Internal Docs") }}
                </code>
            </pre>
            </TabPanel>
            <TabPanel header="Generate new docs">
                <h3>Generate new docs</h3>
                <div style="display: flex; align-items: flex-end; justify-content: flex-end;">
                    <Button
                        @click="copySnippet(generateSnippet(org, 'generate_internal_docs', 'Generate Internal Docs'))">Copy</Button>
                </div>
                <pre style="max-width: 100%; max-height: 100%; overflow: auto;">
                <code>
                    {{ generateSnippet(org, "generate_internal_docs", "Generate Internal Docs") }}
                </code>
            </pre>
            </TabPanel>
            <TabPanel header="Create Issues">
                <h3>Create Issues</h3>
                <div style="display: flex; align-items: flex-end; justify-content: flex-end;">
                    <Button
                        @click="copySnippet(generateSnippet(org, 'generate_internal_docs', 'Generate Internal Docs'))">Copy</Button>
                </div>
                <pre style="max-width: 100%; max-height: 100%; overflow: auto;">
                <code>
                    {{ generateSnippet(org, "generate_internal_docs", "Generate Internal Docs") }}
                </code>
            </pre>
            </TabPanel>
        </TabView>
    </Dialog>


</template>

<script>
import GithubDrawer from '@/components/GithubDrawer.vue';
import GithubTabs from '@/components/GithubPopup.vue';
import Button from 'primevue/button';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css'; // or any other theme you prefer
import 'prismjs/components/prism-yaml'
import APIKeySettings from '@/components/APIKeySettings.vue';

import Popover from 'primevue/popover';

import {
    Setting, Edit
} from '@element-plus/icons-vue'


var cutItemsBy4 = function (bigarray) {
    var size = 3; var arrayOfArrays = [];
    for (var i = 0; i < bigarray.length; i += size) {
        arrayOfArrays.push(bigarray.slice(i, i + size));
    }

    return arrayOfArrays
}

const waitOneSecond = () => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, 1000); // 1000 milliseconds = 1 second
    });
};


export default {
    watch: {
        selectedCategories(val) {
            if (val.length == 0) {
                this.vsCodeblocked = false
                this.chromeBlocked = false
                this.githubActionBlocked = false
                this.deployBlocked = false
            } else {
                console.log("what is the val", val)
                let selectedOptions = val.map(function (category) {
                    if (category.name) return category.name
                    else return category
                })
                this.vsCodeblocked = !selectedOptions.includes("vscode")
                this.chromeBlocked = !selectedOptions.includes("chrome")
                this.githubActionBlocked = !selectedOptions.includes("gtihub_action")
                this.deployBlocked = !selectedOptions.includes("docs_site")
                console.log(selectedOptions)
            }

        }
    },
    data: () => ({
        sectionsGroups: null,
        org: null,
        githubActionsDialog: false,
        selectedCategories: [],
        documentationCategories: [
            { key: "Generate documentation for my codebase and user-facing doc sites from my code editor", name: "vscode" },
            { key: "Generate onboarding wikis for my codebase from CI/CD", name: "gtihub_action" },
            { key: "Spin up a user-facing documentation sites for my product, APIs, and SDKs that uses Dev-Docs AI", name: "docs_site" },
            { key: "Generate step by step guides for my app's UI", name: "chrome" }
        ],
        showOnboarding: true,
        onboardingValues: [],
        onboardingOptions: ['Onboarding docs To help my team learn about my codebase!!', 'Public User Facing Documentation that helps teaches people to use my product'],
        onboardingProgress: 1,
        integrationDrawer: false,
        docsUrl: null,
        activePanel: null,
        showEditUrl: false,
        vsCodeblocked: false,
        chromeBlocked: false,
        githubActionBlocked: false,
        deployBlocked: false,
        newUser: "",
        githubInfo: null,
        vscodeOption: "",
        vscodeOptions: ["2 Minute Quickstart", "VS Code Installation page"],
        repoData: null,
        vercelInfd: null,
        showConnectedGithub: false,
        connectedGitUrl: null,
        chatSettings: null,
        playLoom: false,
        filterIcon: require("@/assets/filter.svg"),
        deployIcon: require("@/assets/deploy.svg"),
        githubIcon: require("@/assets/github.png"),
        logo: require('@/assets/logoicon.svg'),
        chromeIcon: require("@/assets/chrome.png"),
        users: []
    }),
    components: {
        Setting,
        GithubDrawer,
        GithubTabs,
        Edit,
        Button,
        Popover,
        APIKeySettings
    },
    methods: {
        syncChatEdits(val) {
            console.log("what is the val", val)
            this.chatSettings = val
        },
        toggleFilter(event) {
            this.$refs.filter.toggle(event);
        },
        async copySnippet(text) {
            try {
                await navigator.clipboard.writeText(text);
                console.log('Text copied to clipboard');
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        },
        toggle(event) {
            this.$refs["vscode-op"].toggle(event);
        },
        async showRelevantsOption(onboardingOptions) {
            if (onboardingOptions.includes("Onboarding Docs To help my team learn about my codebase")) {
                this.vsCodeblocked = false
                this.docsBlocked = false
                this.githubActionBlocked = false
            }

            if (onboardingOptions.includes("Public User Facing Documentation that helps teaches people to use my product")) {
                this.chromeBlocked = false
                this.vsCodeblocked = false
                this.docsBlocked = true
            }



            console.log(this.onboardingOptions)

        },
        setupFullscreenHandler() {
            // const loomIframe = this.$refs.loomIframe;
            // console.log(loomIframe)
            // loomIframe.addEventListener('click', () => {
            //     if (loomIframe.requestFullscreen) {
            //         loomIframe.requestFullscreen();
            //     } else if (loomIframe.webkitRequestFullscreen) {
            //         loomIframe.webkitRequestFullscreen();
            //     } else if (loomIframe.msRequestFullscreen) {
            //         loomIframe.msRequestFullscreen();
            //     }
            // });
        },
        async scrollToGetStarted() {
            this.activePanel = '0'
            await waitOneSecond()

            const container = this.$refs.sectionholder
            const element = this.$refs.setup

            if (container && element) {
                const elementPosition = element.offsetTop - container.offsetTop;
                container.scrollTo({
                    top: elementPosition,
                    behavior: 'smooth'
                });
            }



            // const element = this.$refs.setup;
            // element.scrollIntoView({ behavior: 'smooth' });
        },
        toggleDeployOptions(event) {
            this.$refs.deployToggle.toggle(event);
        },
        toggleGithubOptions() {
            this.$refs.githubToggle.show(event);
        },
        async getIntegrations() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                // var user = this.$authInstance.getUserInfo()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }
                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
                var saveResponseTwo = await fetch(`${url}/external_githubs?decrypt=yes`, requestOptions)

                var response = await saveResponseTwo.json()

                var integration = response?.docs[0]
                this.githubInfo = integration
                const { content } = integration

                this.docsUrl = content?.url

                //this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
            } catch (e) {

            }

        },
        async submitUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ github_user: user.name, url: this.docsUrl })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_docs_url`, requestOptions)
                var result = await saveResponseTwo.json()

            } catch (e) {

            }

        },
        generateSnippet(org, endpoint, name, options = {}) {
            // this.$snippetsInstance.githubActionCode(org, "generate_internal_docs", "Generate Internal Docs")
            return this.$snippets.githubActionCode(org, endpoint, name)
        },
        async checkForExistingGithub() {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/created_repo`, requestOptions)

                var result = await saveResponseTwo.json()

                this.connectedGitUrl = result?.clone_url
                this.repoData = result
                if (!result?.clone_url) {
                    let authenticated = await this.$authInstance.checkAuth()
                    if (authenticated == 'unauthenticated' || authenticated == undefined) {
                        await this.$authInstance.logout()
                    } else {
                        await this.createGithub()
                        await this.checkForExistingGithub()
                    }
                }
                this.users = result.users.filter(function (user) {
                    return !user.login.includes("avb")
                })
            } catch (e) {

            }

        },
        async checkForVercelUrl() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                var requestOptions = {
                    method: 'get',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/vercel_project`, requestOptions)
                var result = await saveResponseTwo.json()

                if (result.status && result.exists) this.vercelUrl = `https://${org}-dev-docs.vercel.app`
            } catch (e) {

            }
        },
        async createGithub() {


            // this.updateAttributes({ saved: true })
            if (this.connectedGitUrl) {
                this.showConnectedGithub = true
                return
            }
            var myHeaders = new Headers();
            // myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/fork`, requestOptions)
                var result = await saveResponseTwo.json()

                // this.showConnectedGithub = true
                this.connectedGitUrl = result.clone_url
            } catch (e) {

            }

        },
        async addGithubUser() {


            // this.updateAttributes({ saved: true })

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = { name: this.newUser }


                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/external_users`, requestOptions)
                var result = await saveResponseTwo.json()

                this.showConnectedGithub = true
                this.connectedGitUrl = result.clone_url
            } catch (e) {

            }

        },
        async pushToVercel() {
            // this.updateAttributes({ saved: true })
            var myHeaders = new Headers();
            try {
                var org = await this.$authInstance.getOrg()
                var token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var user = await this.$authInstance.userinfo()

                // const { content } = this.githubInfo
                var raw = JSON.stringify({ user })
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var saveResponseTwo = await fetch(`${url}/vercel_project`, requestOptions)
                var result = await saveResponseTwo.json()

            } catch (e) {
                console.log(e)
            }
        }
    },
    computed: {
        isPublished() {
            return this.$route.name == "published documentation"
        },
        progress() {
            return ``
        }
    },
    async mounted() {
        var sections = [{ "label": "Guides", "value": "guides" }, { "label": "Sample Apps", "value": "sample apps" }, { "label": "Concepts", "value": "concepts" }, { "label": "Blog", "value": "blog" }, { "label": "Rest API Reference", "value": "rest api" }, { "label": "Community Content", "value": "community" }]
        this.sectionsGroups = cutItemsBy4(sections)
        let authenticated = await this.$authInstance.checkAuth()
        if (authenticated == 'unauthenticated' || authenticated == undefined) {
            await this.$authInstance.logout()
        }
        this.setupFullscreenHandler()
        if (this.$route.params.org) this.org = this.$route.params.org
        else this.org = await this.$authInstance.getOrg()
        // await this.getIntegrations()
        // await this.checkForExistingGithub()
        // await this.checkForVercelUrl()

    }

}

</script>

<style>
.p-blockui {
    display: flex !important;
    flex-basis: 30% !important;
}

.p-popover {
    z-index: 3000 !important;
}

.p-blockui .p-card {
    flex-basis: 100% !important;
    max-height: unset !important;
}

.popover-content {
    background: #030711 !important;
    color: white !important;
    border-radius: 20px !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
}


.footer-actions {
    justify-content: flex-end;
    flex-wrap: wrap !important;
}



.sections-holder {
    padding: 2em;
    height: 80vh;
}

.external-docs-grid {
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 2em;
    min-height: 80ch;
}

.guides-content {
    background: #2C2738 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.apps-content {
    background: #14A38B !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.concepts-content {
    background: #736BF5 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.blogs-content {
    background: #FAB5B5 !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.api-content {
    background: #409EFF !important;
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
    color: white !important;
}

.community-content {
    box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02), 0px 24px 48px rgba(44, 39, 56, 0.04) !important;
    border-radius: 20px !important;
}

.onboarding-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
}

.gradient-heading {
    display: flex;
    align-items: center;
    text-align: center;
    color: #ccc;
}

.filled-heading {
    background: linear-gradient(90deg, #7984EB 0%, #E270FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
}

.gradient-heading::before,
.gradient-heading::after {
    content: "-";
    color: transparent;
    flex: 1;
    width: 11.78125rem;
    height: 2px;
    background: linear-gradient(90deg, #7984EB 0%, #E270FF 100%);
    margin: 0 10px;
}
</style>